<template>
  <div>
    <p class="text-2xl mb-6">Comandas por producto</p>

    <v-card>
      <v-card-subtitle>Filtros</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <select-puesto :storePuesto="true" @getPuesto="getPuesto" :dense="true" :outlined="true" :filtros="{id_reporte : 8}" ></select-puesto>
          </v-col>
          <v-col cols="12" sm="6">
            <DatePicher :dense="true" :outlined="true" v-model="fechas"></DatePicher>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn :loading="cargando" :disabled="cargando || !validar()" color="primary" @click="buscarDatos()">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle>Resultado</v-card-subtitle>
      <v-card-text>
        <tabla-reporte
          @GetVer="GetVerComanda"
          :nombreReporte="nombreReporte"
          :cabeceraIn="cabecera"
          :datosIn="datos"
          :exportar="['EXCEL', 'PDF']"
        ></tabla-reporte>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import TablaReporte from '@/components/TablaSimpleReporte.vue'
import { ref } from '@vue/composition-api'

import SelectPuesto from '../../../archivo/puesto/components/SelectPuesto.vue'
import MaestroService from '@/api/servicios/MaestroService'
import moment from 'moment'
import store from '@/store'
import DatePicher from '@/components/DatePicker.vue'
import ComandaService from '@/api/servicios/ComandaService'
// demos

export default {
  components: { TablaReporte, DatePicher, SelectPuesto },
  setup() {
    const nombreReporte = ref(`Comadas por producto `)

    const cabeceraExcel = ref([
      {
        puesto: 'Locatario/Area',
        desde: 'Desde',
        hasta: 'Hasta',
        nombre: 'Producto',
        cant: 'Cant',
        total: 'Total',
      },
    ])

    const cabecera = ref([
      { text: 'Fecha', value: 'fecha', format: { small: true } },
      { text: 'Fecha Hora', value: 'fecha_hora', format: { small: true, dateTime: true } },
      { text: 'Comanda', value: 'id', format: { small: true } },
      { text: 'Caja', value: 'caja', format: { small: true } },
      { text: 'Cajera', value: 'cajera', format: { small: true } },
      { text: 'Mesonero', value: 'mesonero', format: { small: true } },
      { text: 'Cliente', value: 'cliente', format: { small: true } },
      { text: 'Producto', value: 'nombre_producto', format: { small: true } },
      { text: 'Precio', value: 'precio', format: { small: true } },
      { text: 'Cant', value: 'cant', format: { small: true } },
      { text: 'Total', value: 'total', format: { small: true } },
      { text: 'Total Comanda', value: 'total_comanda', format: { small: true } },
    ])
    const datos = ref([])
    const fechas = ref(null)
    const cargando = ref(false)
    const puesto = ref({})
    const validar = () => {
      let validado = true
      if (puesto.value.id == undefined) {
        validado = false
      }
      if (fechas.value == undefined) {
        validado = false
      }

      return validado
    }
    const buscarDatos = () => {
      cargando.value = true

      var parametros = {
        fecha: fechas.value,
        id_puesto: puesto.value.id,
      }
      ComandaService.reporteComandaPorProducto(parametros)
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = response.data.datos
            nombreReporte.value = `comadas por producto  ${puesto.value.nombre}  al ${fechas.value}`

            if (datos.value.length == 0) {
              store.commit('setAlert', {
                message: 'No hubo resultado a la busqueda',
                type: 'info',
              })
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'waring',
            })
          }
        })
        .catch(err => {})
        .finally(() => {
          cargando.value = false
        })
    }

    const getPuesto = datos => {
      puesto.value = datos
    }
    return {
      cabecera,
      datos,
      getPuesto,
      fechas,
      puesto,
      validar,
      buscarDatos,
      cargando,
      cabeceraExcel,
      nombreReporte,
    }
  },
}
</script>
